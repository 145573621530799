import router from "next/router"
import { useEffect } from 'react'
export const AssistantLanding = ({increaseStep}) => {

    useEffect(() => {
        increaseStep();
    }, [])
    return <p> loading... </p>
    const steps = [
        {stepBackground: 'assets/images/assistant/landing/background-step-01.png', stepDescription: 'Escoge el espacio que quieras diseñar'},
        {stepBackground: 'assets/images/assistant/landing/background-step-02.png', stepDescription: 'Elige los materiales y estilo de decoración'},
        {stepBackground: 'assets/images/assistant/landing/background-step-03.png', stepDescription: 'Define la paleta de colores que tendrá tu espacio'},
        {stepBackground: 'assets/images/assistant/landing/background-step-04.png', stepDescription: 'Recibe una selección de productos especialmente para ti'},
    ]
    return (
        <div className="assistant container">
            <div className="assistant-header">Bienvenido al asistente inteligente virtual de <span className="sanmarino-logo-text"><span>SAN</span>MARINO</span></div>
            <div className="assistant-subheader">
                Nuestro asistente te guiará según tus preferencias para que encuentres la inspiración y el estilo perfecto.
                Tenemos todo lo que necesitas para crear tu espacio favorito!
            </div>
            <div className="assistant-center-content">
                <div className="content-options-row">
                    {steps.map((step, index)=> {
                        return (
                            <div className="option-wrapper" style={{backgroundImage: `url(${step.stepBackground})`}}>
                                <div className="option-header-wrapper">
                                    <div className="option-header-step">PASO</div>
                                    <div className="option-header-step-number">0{index + 1}</div>
                                </div>
                                <div className="step-description-wrapper">
                                    <div className="step-description">{step.stepDescription}</div>
                                </div>
                            </div>
                        )
                    })}
                </div> 
                <div onClick={()=>increaseStep()} className="assistant-arrow-btn btn">CONTINUAR<img src={'assets/images/assistant/icons/arrow-right.svg'}/></div>
            </div>
        </div>
    )
}

