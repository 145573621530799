import { gql } from "@apollo/client"

export const CreateAssistantSession = gql`
    mutation initializeAssistantSession( $input: AssistantSessionInput! ) {

        createAssistantSession(data: $input) 
        {
            data {
                attributes {
                    values
                    finished
                    path
                    step
                    reference
                    substep
                    wallAreaToCover
                    floorAreaToCover
                }
            }
        }
    }
`