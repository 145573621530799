import Head from "next/head";
import { Fragment, useState } from 'react'
import { HeaderSix as Header } from "@components/header";
import { FooterOne as Footer } from "@components/footer";
import { HomePagesNavData as navContent } from "@data/navbar";
import Breadcrumb from "@components/ui/breadcrumb";
import {ContentWrapperOne as ContentWrapper} from "@components/wrapper";
import { AssistantFlow } from "@components/assistant/assistantFlow"
import { AssistantLanding } from "@components/assistant";
import { AssistantSelector } from "@components/assistant/AssistantSelector";

const Assistant = () => {
  const logo = "/assets/images/brand/nobg-logo.svg";
  const [showAssistantSelector, setShowAssistantSelector] = useState(false);
  // const [assistantSelection, setAssistantSelection] = useState('')
  const increaseStep = () => {
    setShowAssistantSelector(true)
  }
  return (
    <Fragment>
        <Head>
            <title>Asistente Inteligente - San Marino</title>
            {/* <script src="https://sdk.mercadopago.com/js/v2"></script> */}
        </Head>
        <Header
            logo={logo}
            navbar={true}
            navData={navContent}
            containerFluid={true}
            logoAlignment="left"
            navbarAlignment="left"
        />
        <ContentWrapper>
          <Breadcrumb/>
          { !showAssistantSelector
            ? 
            <AssistantLanding increaseStep={() => increaseStep()}/> 
            :
            <AssistantSelector setShowAssistantSelector={setShowAssistantSelector}/>
          }
        </ContentWrapper>
      <Footer logo={logo} dark={false} newsletter={false} />
    </Fragment>
  );
};
export default Assistant;